.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 850px;
  padding: 1.1rem !important;
  box-sizing: border-box;
  overflow-x: visible !important;
}

.searchFilters,
.searchResults {
  margin-top: 1em;
  border: 0.1rem solid #ddd;
  border-radius: 0.2rem;
}

.searchFilters h2,
.searchResults h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

/*********************/
/**** Search Bar *****/
/*********************/

.searchBar {
  display: flex;
}

.searchBar > input,
.searchBar > .categories {
  border: 0.1rem solid #ddd;
  font-size: 1.045rem;
  padding: 0.35em 0.5em 0.35em 0.5em;
}

.searchBar > input {
  border-radius: 0.2rem 0 0 0.2rem;
  flex: 1;
  padding-left: 2.5em;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.searchBar > .categories {
  border-radius: 0 0.2rem 0.2rem 0;
  flex: 1;
  max-width: 15ch;
  position: relative;
  border-left: none;
}

.categoriesDropdownTitle {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.dropdownArrow {
  filter: contrast(0.7);
  background-image: url("../Images/DropdownArrow.png");
  width: 30px;
  height: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
}

.dropdownArrow:hover {
  filter: contrast(0.9);
}

.categoriesDropdown {
  position: absolute;
  border: 0.1rem solid #ddd;
  background-color: rgb(245, 245, 245);
  transform: translateX(-1px);
  z-index: 99;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 0 0.2rem 0.2rem 0.2rem;
}

.category {
  position: relative;
}
.category:not(:nth-child(-n + 2)) {
  margin-left: 0.5rem;
}

/************************/
/**** Search Filters ****/
/************************/

.searchFilters {
  padding: 1rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filter,
.info {
  height: 2.25rem;
  background-color: rgb(240, 240, 240);
  border-radius: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 0 0.5rem;
}

.filter > img,
.info > img {
  width: 1.35rem;
  height: 1.35rem;
}

.filter > span,
.info > span {
  font-size: 1rem;
}

.filter.button,
.info.button {
  cursor: pointer;
}
.filter.button:hover,
.info.button:hover {
  background-color: rgb(230, 230, 230);
}

.plusTag {
  height: 1.2rem !important;
  width: 1.2rem !important;
  cursor: pointer;
}

.plusTag:hover {
  transform: scale(1.05);
}

.tag {
  background-color: rgb(210, 232, 255);
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.tag > img {
  height: 0.8rem !important;
  width: 0.8rem !important;
  padding-left: 0.25rem;
}

.machineSettings {
  display: grid;
  gap: 0.25rem;
  min-width: 15rem;
}

.machineSettings h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.machineSettingsElement {
  display: flex;
  align-items: center;
}

.machineSettingsElement > span {
  margin-right: 0.5rem;
}

.machineSettingsElement > select,
.machineSettingsElement input {
  border: 1px solid rgb(130, 130, 130);
  padding: 0.2em 0.5em;
  font: inherit;
  border-radius: 0.5em;
  box-sizing: border-box;
}

.machineSettingsElement > select {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
  margin-left: auto;
}

.machineSettingsElement > input {
  border-radius: 0 0.25rem 0.25rem 0;
}

.machineSettingsElement > select {
  cursor: pointer;
}

.machineSettingsElement > select:focus,
.machineSettingsElement input:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}

/************************/
/**** Search Results ****/
/************************/

.searchResults {
  position: relative;
  display: grid;
  gap: 1px;
  grid-template-columns: 1.25fr 0.75fr 1.25fr 0.75fr;
  background-color: #ddd;
  overflow-y: auto;
}

.noResults {
  grid-column: span 4;
}

.searchResults > * {
  background-color: white;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.tableTitle {
  font-weight: 600;
  background-color: rgb(245, 245, 245);
  position: sticky;
  top: 0;
  border-bottom: #ddd 1px solid;
  margin-bottom: -1px;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .tableCell.hasActionIcon {
    justify-content: space-around !important;
  }
}
.tableCell:has(.actionIcon) {
  justify-content: space-around !important;
}

.actionIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

/****************/
/**** Modals ****/
/****************/

.downloadModalBtns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.downloadModalBtn,
.actionButton {
  flex: 1;
  display: inline-flex;
  align-items: center;
  background-color: rgb(232, 232, 232);
  padding: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.downloadModalBtn:hover,
.actionButton:hover {
  background-color: rgb(209, 209, 209);
}

.downloadModalBtn em,
.actionButton em {
  margin-left: 0.25em;
}

.savePopUp {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.6em;
  margin-left: 0.4em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
}

.jsonIcon {
  background-image: url("../Images/JSON.png");
}

.tableIcon {
  background-image: url("../Images/Table.png");
}

.downloadIcon {
  background-image: url("../Images/DownloadIcon.png");
}

.saveIcon {
  background-image: url("../Images/AddIcon.png");
}

.sampleIcon {
  background-image: url("../Images/SoundWave.png");
}

.seeRelatedSamples {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.infos {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.socialMedia {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.socialMediaIcon {
  width: 20px;
  height: 20px;
  background-size: 100%;
}

.researcherDetail {
  margin-top: 1rem;
  font-weight: 600;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .preview.hasResearcherDetails {
    min-width: 15rem;
    max-width: 30rem;
  }
}
.preview:has(.researcherDetails) {
  min-width: 15rem;
  max-width: 30rem;
}

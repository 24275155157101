.legendCheckbox {
  font-weight: 550;
  cursor: pointer;

  label {
    margin-left: 0.25rem;
  }
}

.thresholdSettingsBtn:hover {
  background-color: #e0e0e0;
}

.thresholdSettingsBtn {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  cursor: pointer;

  div {
    background-image: url("../Images/SettingsIcon.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  }
}

.thresholdISOSheet img {
  max-width: min(100vw, 780px);
}

.sensorHover text {
  stroke-width: 0;
  font-weight: 500;
}

.sensorHover {
  fill: rgba(255, 255, 255, 0.95);
  stroke: rgb(160, 160, 160);
  stroke-width: 2;
  cursor: pointer;
  z-index: 2;
}

.sensorHover:hover {
  fill: rgba(240, 240, 240);
}

/* Header (buttons) */
.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.sensorSettings {
  background-image: url("../Images/SettingsIcon.png");
  background-size: contain;
}

.graphManager {
  background-image: url("../Images/Layers.png");
  background-size: contain;
}

/* Threshold settings pop-up */
.thresholdHeader {
  font-weight: 550;
}

.thresholdInputs {
  display: flex;
  flex-wrap: wrap;
}

.thresholdInputs>div {
  width: 200px;
  padding-bottom: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.thresholdInputs span {
  display: block;
  padding-right: 0.5em;
}

.thresholdInputs input,
.thresholdInputs select {
  margin-top: 0.25em;
  width: 170px;
  font-size: 0.9em;
  margin-right: 1em;
}

.thresholdType {
  padding-top: 0.5em;
  width: 150px;
}

.thresholdType label {
  padding-right: 1em;
}

.thresholdType input {
  width: 10px;
  margin-right: 0.5em;
}

.aboutThresholdsISO {
  width: 30px !important;
  height: 35px !important;
  background-image: url('../Images/HelpIcon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  align-self: flex-end;
  filter: contrast(0.3);
  transform: translate(-10px, -2px);
  cursor: pointer;
}

.aboutThresholdsISO:hover {
  filter: contrast(0.6);
}

.mobileTitleButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileTitleButton:hover {
  filter: contrast(1.5);
}
.mobileTitleButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

.historyDropdownOption {
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;
  background-color: #fefefe;
  padding: 0.25rem;

  input {
    border: 1px solid gray;
  }
} 
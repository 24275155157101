.collaboratorSearch {
  position: relative;
  display: flex;
  align-items: center;
  height: 2.25em;
}

.collaboratorSearch > input {
  box-sizing: border-box;
  height: 100%;
  background-color: transparent;
  padding-left: 0.5em;
  padding-right: 0;
  border: none;
  flex-grow: 1;
  font-size: 0.95em;
  z-index: 1;
  border: 1px solid rgb(142, 142, 142);
}

.collaboratorSearch > input:hover + .copyIcon,
.collaboratorSearch > .copyIcon:hover {
  filter: invert(0%);
}

.copyIcon {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  cursor: pointer;
  right: 14ch;
  cursor: pointer;
  filter: invert(100%);
  z-index: 2;
}

.collaboratorSearch > div {
  background-color: var(--button);
  color: white;
  width: 2em;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collaboratorSearch > select {
  box-sizing: border-box;
  width: 118px;
  height: 100%;
  background-color: #f2f2f2;
  padding: 1px;
  box-sizing: border-box;
  border: 1px solid rgb(142, 142, 142);
  border-left: none;
  font-weight: 600;
  font-size: 0.95em;
  cursor: pointer;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .collaboratorSearch > select {
    padding-left: 5px;
  }
  .collaboratorSearch option {
    font-weight: 400;
  }
}

.collaboratorSearch > div:hover {
  background-color: var(--buttonHover);
}

.collaboratorList {
  max-height: 200px;
  flex-grow: 1;
  overflow: auto;
}

.collaboratorItem {
  display: flex;
  border-style: solid;
  border-color: rgb(142, 142, 142);
  border-width: 0 1px 1px 1px;
}
.collaboratorItem:first-child {
  border-top-width: 1px;
}

.collaboratorItem > span {
  flex-grow: 1;
  padding: 0.4em 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collaboratorItem > div {
  display: flex;
  align-items: center;
}

.collaboratorItem > div > span {
  padding-left: 0.25em;
}

.collaboratorItem > select > option {
  font-weight: 400;
}

.collaboratorItem > select {
  border: none;
  background-color: #f2f2f2;
}

.collaboratorItem > div,
.collaboratorItem > select {
  width: 100px;
  box-sizing: border-box;
  border-left: 1px solid rgb(226, 226, 226);
  font-weight: 600;
  font-size: 0.9em;
}

.resultsDropdownWrapper {
  margin-bottom: 0.75em;
  border-radius: 0 0 0.5em 0.5em;
  position: relative;
  box-sizing: border-box;
}

.resultsDropdown {
  max-height: 100px;
  overflow: auto;
  width: calc(100% - 2px);
  transform: translate(1px);
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 0 0 0.5em 0.5em;
  border-bottom: 1px solid rgb(142, 142, 142);
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.resultsDropdown > div {
  border-bottom: 1px solid rgb(142, 142, 142);
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.resultsDropdown > div:hover {
  background-color: rgb(225, 225, 225);
}

.resultsDropdown > div:last-child {
  border-bottom: none;
}

.organizationInfoBasic {
  display: flex;
  margin-bottom: 0.75em;
  align-items: center;
}

.organizationInfoBasic span:first-child {
  font-weight: 600;
  padding-right: 0.25em;
}

.organizationInfoBasic > i {
  transform: translateY(0.1em);
  padding-left: 0.5em;
  color: rgb(130, 130, 130);
  cursor: pointer;
}

.organizationInfoBasic > i:hover {
  color: rgb(84, 84, 84);
}

.teamLbl {
  font-weight: 600;
  display: block;
  margin-bottom: 0.25em;
}

.transferOwner {
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../Images/Transfer.png");
  margin-left: 20px;
  width: 30px;
  height: 30px;
  border-radius: 10%;
  filter: contrast(0.7);
}

.transferOwner:hover {
  filter: contrast(1);
  cursor: pointer;
}

.newOwnerDescription {
  width: 500px;
}

.newOwner > i {
  margin-left: 0.5em;
  cursor: pointer;
}

.newOwner > i:hover {
  transform: scale(1.1);
}

.cancelBtn {
  cursor: pointer;
}

.cancelBtn:hover {
  background-color: rgb(225, 225, 225);
}

@font-face {
  font-family: "WorkSans";
  src: url("./Styles/Fonts/WorkSans.ttf");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: url("./Styles/Fonts/OpenSans.ttf");
  font-display: swap;
}

@font-face {
  font-family: "FugazOne";
  src: url("./Styles/Fonts/FugazOne.ttf");
  font-display: swap;
}

:root {
  --logoFont: FugazOne !important;
  --headingFont: WorkSans !important;
  --mainFont: OpenSans !important;

  --primary: rgb(27, 41, 53);
  --background: rgb(238, 238, 238);
  --darkBackground: rgb(37, 96, 147);
  --text: rgb(18, 18, 18);
  --light-text: rgb(245, 245, 245);

  --links: #034783;
  --button: rgb(50, 100, 150);
  --buttonHover: rgb(33, 77, 116);
  --darkerColor: rgb(11, 38, 62);
  --border: 1px solid rgb(193, 193, 193);
  --menuHeight: 56px;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  input[type="radio"] {
    transform: translateY(-1px);
  }
}

/*LEAFLET*/
.leaflet-container {
  width: 100%;
  height: 100%;
}

/*
**
** TABS
**
*/

.rc-dyn-tabs-panellist {
  padding: 0px;
  margin-top: var(--menuHeight);
  height: calc(100% - 78px);
  display: none;
}

.rc-dyn-tabs-panel {
  padding: 0;
  position: relative;
  height: 100%;
}

.rc-dyn-tabs-selected {
  display: flex !important;
}

.rc-dyn-tabs-tablist {
  border-bottom: none;
  background-color: transparent !important;
  background-color: var(--primary);
  height: 100%;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  z-index: 100 !important;
}

li[tab-id="devicesTab"],
li[tab-id="searchTab"] {
  margin-left: auto;
}

li[tab-id="devicesTab"] > span,
li[tab-id="samplesTab"] > span,
li[tab-id="searchTab"] > span {
  display: none;
}

li[tab-id="devicesTab"] > button,
li[tab-id="samplesTab"] > button,
li[tab-id="searchTab"] > button {
  min-width: 0 !important;
  max-width: none !important;
  border-left: var(--menuLines) !important;
  border-right: none !important;
  font-weight: 350 !important;
  font-size: 1.5em;
  line-height: normal;
}

li[tab-id="devicesTab"] > button > span,
li[tab-id="samplesTab"] > button > span,
li[tab-id="searchTab"] > button > span {
  position: unset !important;
  color: var(--light-text);
  font-size: 1em;
}

@media only screen and (max-width: 800px) {
  li[tab-id="devicesTab"] > button,
  li[tab-id="samplesTab"] > button {
    width: 60px !important;
  }

  li[tab-id="devicesTab"] > button:before,
  li[tab-id="samplesTab"] > button:before {
    content: "";
  }

  li[tab-id="devicesTab"] > button > span,
  li[tab-id="samplesTab"] > button > span,
  li[tab-id="searchTab"] > button > span {
    padding-right: 0em !important;
  }
}

@media only screen and (min-width: 800px) {
  li[tab-id="devicesTab"] > button:before {
    content: "Devices";
  }

  li[tab-id="samplesTab"] > button:before {
    content: "Samples";
  }

  li[tab-id="devicesTab"] > button > span,
  li[tab-id="samplesTab"] > button > span {
    padding-right: 0.4em !important;
  }

  li[tab-id="searchTab"] > button > span {
    padding-right: 0em !important;
  }
}

.li[tab-id="devicesTab"] > button:hover,
.li[tab-id="samplesTab"] > button:hover,
.li[tab-id="searchTab"] > button:hover {
  background-color: rgb(14, 28, 42) !important;
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab.rc-dyn-tabs-selected {
  background-color: transparent !important;
}

.rc-dyn-tabs-tablist > li {
  display: block;
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab > .rc-dyn-tabs-title {
  font-weight: 500;
  border-radius: 0 !important;
  border-right: var(--menuLines);
  transition: none;
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab > .rc-dyn-tabs-title:hover {
  background-color: rgb(14, 28, 42);
  border-right: var(--menuLines);
}

.rc-dyn-tabs-title {
  color: var(--light-text) !important;
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab.rc-dyn-tabs-selected {
  background-color: transparent;
}

.darkMode
  .rc-dyn-tabs-tablist
  .rc-dyn-tabs-tab.rc-dyn-tabs-selected
  > .rc-dyn-tabs-title {
  background-color: var(--darkBackground);
}

.rc-dyn-tabs-tablist
  .rc-dyn-tabs-tab.rc-dyn-tabs-selected
  > .rc-dyn-tabs-title {
  background-color: var(--button);
  border: none;
  border-right: var(--menuLines);
}

.rc-dyn-tabs-tablist
  .rc-dyn-tabs-tab.rc-dyn-tabs-selected
  > .rc-dyn-tabs-title:hover {
  background-color: #1f4f79 !important;
}

.rc-dyn-tabs-close {
  color: rgb(255, 255, 255);
  font-size: 2em;
  right: 0.1em !important;
  top: 0.1em !important;
  width: 0.6em;
  height: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mobileTabs .rc-dyn-tabs-tab button > span {
  display: none;
}

.rc-dyn-tabs-tab button > span {
  position: absolute;
  left: 0.5em;
  top: 0.3em;
  font-size: 0.9em;
  color: #7099bd;
}

.rc-dyn-tabs-tab button {
  height: 100%;
  min-width: 0;

  font-family: var(--mainFont);
  font-size: 1.05em;

  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-align: left !important;

  display: block !important;
  flex-direction: row-reverse;
  justify-content: flex-end !important;
  line-height: 48px;
}

/*
**
** LEAFLET MAP
**
*/

.leaflet-bottom.leaflet-right {
  left: 0 !important;
  right: auto !important;
}

.leaflet-popup-content {
  margin: 0em 0.75em !important;
}

.react-resizable-handle {
  position: absolute;
  width: 25px;
  height: 25px;
  z-index: 400 !important;
  cursor: se-resize;
  background-image: url("./Images/resize.png");
  background-size: contain;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100em 0em 0em 0em;
}

/*
**
** FILE BROWSER
**
*/

.nameLbl span {
  padding-right: 0.5em;
  transform: translateY(-3px);
  font-size: 0.75em;
  display: inline-block;
}

.rendered-react-keyed-file-browser {
  display: flex;
  flex-direction: column;
  padding: 1em 1em;
}

@media (max-width: 605px), (max-height: 465px) {
  .rendered-react-keyed-file-browser {
    padding: 0.5em 0.5em;
  }
}

.rendered-file-browser {
  flex-shrink: 1;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

#mobileTabs .rc-dyn-tabs-close {
  display: block;
  transform: translateY(-5px);
  height: 1em;
  width: 1em;
  font-size: 2.75em;
  margin-right: 3%;
}

#mobileTabs > ul > li > button {
  width: 100%;
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247) !important;
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em !important;
}
#mobileTabs > ul > li > button:hover {
  filter: contrast(1.5);
}
#mobileTabs > ul {
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-sizing: border-box;
  gap: 1em;
}
#mobileTabs > ul > li[tab-id="devicesTab"],
#mobileTabs > ul > li[tab-id="samplesTab"],
#mobileTabs > ul > li[tab-id="searchTab"] {
  display: none !important;
}

@media (max-width: 565px) {
  .rowBtn {
    width: 12px;
    flex-shrink: 0;
    min-height: 10px;
  }
  .browserTab {
    padding: 0.5em 1em !important;
    font-size: 0.85em;
  }
}

.rowBtn {
  display: flex;
  align-items: center;
  background-color: var(--light-text);
  font-size: 0.9em;
  font-weight: 400 !important;
  padding: 0 0.4em;
  border-radius: 0.5em;
  color: #125998;
}

.rowBtn:hover {
  cursor: pointer;
  background-color: rgb(234, 234, 234);
  border: 1px solid #a0a0a0;
}

.deleting button {
  border: 1px solid rgb(255, 86, 86);
  background-color: rgb(255, 198, 198);
  font-size: 0.8em;
  padding: 0.3em 0.5em;
  margin-top: 0.5em;
  border-radius: 0.25em;
}

.deleting button:hover {
  cursor: pointer;
  border: 1px solid rgb(255, 0, 0);
  background-color: rgb(255, 150, 150);
}

.device-settings {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  padding-left: 0.4em;
  width: 20px;
  height: 20px;
  color: rgb(105, 105, 105);
}

.rendered-file-browser > .leaflet-container {
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: var(--border);
  border-top: 0;
  box-sizing: border-box;
}

.device-settings i {
  font-size: 1em;
}

.device-settings:active,
.rowBtn:active {
  transform: translateY(1px);
}

.device-settings:hover {
  cursor: pointer;
  color: rgb(56, 56, 56);
}

.linked-in-icon {
  display: flex;
  align-items: center;
}

.action-bar > input {
  width: 100%;
  font-size: 0.95em;
  box-sizing: border-box;
  background-color: white !important;
  border: var(--border);
  border-right: none;
  display: block;
  padding-left: 2.5em !important;
  padding: 0.35em 0em 0.35em 2.5em !important;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
  background-color: transparent;
  border-radius: 0.2em;
  margin-bottom: 0.6em;
}

.gatewayFileIcon {
  background-image: url("./Images/GatewayFileIcon.png");
  width: 1.25em !important;
  height: 1.25em !important;
}

.folderAddFileIcon {
  background-image: url("./Images/FolderAddIcon.png") !important;
}

.orgFileIcon {
  background-image: url("./Images/Group.png");
}

.leaveOrgFileIcon {
  background-image: url("./Images/LeaveGroup.png");
}

.vibrationFileIcon {
  background-image: url("./Images/SoundWave.png");
}

.temperatureFileIcon {
  background-image: url("./Images/TemperatureIcon.png");
}

.audioFileIcon {
  background-image: url("./Images/AudioIcon.png");
}

.uploadIcon {
  background-image: url("./Images/UploadIcon.png");
}

.downloadIcon {
  background-image: url("./Images/DownloadIcon.png");
}

.saveIcon {
  background-image: url("./Images/AddIcon.png");
}

.folderFileIcon {
  background-image: url("./Images/FolderClosedIcon.png");
}

.renameFileIcon {
  background-image: url("./Images/RenameIcon.png");
}

.deleteFileIcon {
  background-image: url("./Images/GarbageIcon.png");
  padding-right: 0.1em !important;
}

.folderOpenFileIcon {
  background-image: url("./Images/FolderOpenIcon.png");
}

.sensorFileIcon {
  background-image: url("./Images/SensorIcon.png");
}

.shareFileIcon {
  background-image: url("./Images/ShareItem.png");
  padding-right: 0.2em !important;
}

.organizationAddFileIcon {
  background-image: url("./Images/OrganizationAdd.png");
  width: 1.25em !important;
  height: 1.25em !important;
}

.gatewayAddFileIcon {
  background-image: url("./Images/GatewayAdd.png");
  width: 1.25em !important;
  height: 1.25em !important;
}

.tutorialFileIcon {
  background-image: url("./Images/question.png");
  padding-right: 0.15em !important;
  width: 1.05em !important;
  height: 1.05em !important;
  transform: translateY(0px) !important;
  filter: invert(0.3);
}

.reportAddFileIcon {
  background-image: url("./Images/ReportAdd.png");
  padding-right: 0.2em !important;
}

.readmeAddFileIcon {
  background-image: url("./Images/ReportAdd.png");
  padding-right: 0.2em !important;
}

.readmeFileIcon {
  background-image: url("./Images/Report.png");
}

.copyIcon {
  background-image: url("./Images/CopyIcon.png");
}

.item-actions > li > a {
  display: flex;
  height: 1em;
  align-items: center;
}

.item-actions {
  gap: 0.8em;
}

.action-bar {
  flex-direction: column !important;
}

.nameBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  border-bottom: 1px solid #ededed;
  padding: 0.25em 0.5em;
}

.rendered-file-browser > .action-bar > input {
  border: 2px solid #ededed;
}

.rowBtn {
  border: var(--border);
  padding: 0.25em 0.75em;
  margin-left: 0.5em;
}

.files > table tr:hover {
  background-color: rgb(245, 245, 245);
}

.files .selected {
  border-left: 4px solid #1f3344;
}

.files .selected a {
  font-weight: 700;
}

.rowBtn > i {
  margin-right: 0.25em;
}

.nameBox > .nameLbl {
  flex-grow: 1;
}

.nameLbl > a,
.nameLbl > div > a {
  cursor: pointer;
}

.deleting > a > i,
.action-bar > .item-actions i,
.nameBox > div > a > i,
.nameLbl > div > a > i {
  width: 1.3em;
  height: 1.3em;
  background-repeat: no-repeat;
  transform: translateY(0.05em);
  background-size: contain;
  display: inline-block;
  padding-right: 0.4em;
}

div.rendered-react-keyed-file-browser div.files table {
  margin-bottom: 0.5em;
}

.action-bar > .item-actions {
  display: flex !important;
  flex-wrap: wrap;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  margin-left: -44px;
  justify-content: flex-end;
  width: 100%;
}

.action-bar > .item-actions > li {
  list-style: none;
}

.files {
  flex-shrink: 1;
  overflow-y: auto;
}

.nameLbl > .renaming input {
  font-size: 1em;
}

.files > table {
  width: 100%;
}

.rendered-react-keyed-file-browser {
  flex-grow: 1;
  padding-top: 0.75em;
}

.file-browser-header {
  font-size: 1em;
  font-weight: 800;
  padding-top: 0.65em;
  border-bottom: var(--border);
  overflow: auto;
}

.sensor-file-settings {
  display: flex;
  justify-content: right;
  gap: 5px;
  align-items: center;
  max-width: 50%;
  flex-wrap: wrap;
}

.starred.favourited {
  transform: translateY(-0.5px);
  background-image: url("./Images/Starred.png");
}

.starred:hover {
  filter: invert(0.05) brightness(1.2) saturate(1.1);
}

.starred {
  background-image: url("./Images/UnStarred.png");
  width: 23px;
  height: 23px;
  filter: invert(0.2) brightness(1.35) saturate(1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-left: 0.4em;
  flex-shrink: 0;
}

.file-browser-tabs {
  display: flex;
  flex-direction: row;
}

.browserTab {
  font-weight: 600;
  margin-right: 0.2em;
  padding: 0.25em 1.15em;
  border: var(--border);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  border-radius: 0.5em 0.5em 0 0;
  cursor: pointer;
  background-color: rgb(236 245 255);
  display: flex;
  align-items: center;
}

.file-browser-tabs > .selected {
  background-color: rgb(59 120 181);
  color: white;
}

.browserTab:not(.selected):hover {
  background-color: rgb(215, 234, 255);
}

.files table,
.files tr,
.files td,
.files tbody {
  display: block;
}

/*
* Info Tooltip
*/

.__react_component_tooltip {
  font-family: var(--mainFont);
}

/* 
* Popup
*/

.ReactModal__Overlay {
  z-index: 5;
  background-color: rgba(10, 10, 10, 0.8) !important;
}

/*
**
** MORE STUFF
**
*/

select,
option,
input,
textarea {
  font-family: var(--mainFont), tahoma;
}

textarea {
  font-size: 0.95em;
}

/* Portrait Mode (Vertical) */
@media (max-aspect-ratio: 15/14) {
  #graph2 {
    margin-top: 0em !important;
  }
}

/* Landscape Mode (Horizontal Mode) */
@media (min-aspect-ratio: 15/14) {
  #graph2 {
    margin-left: 0em !important;
  }
}

a {
  color: var(--links);
}

html,
body,
#root {
  font-family: var(--mainFont);
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

body {
  background-color: var(--background);
}

.darkMode body {
  background-color: var(--darkBackground);
}

body {
  margin: 0;
  font-family: var(--headingFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0.35em 0 0 0;
  font-size: 1.75em;
  color: var(--text);
}

h1 > i {
  margin-right: 0.25em;
}

h2 {
  margin: 0;
  font-size: 1.4em;
  font-weight: 400;
  font-family: var(--headingFont);
  color: var(--text);
}

#root {
  display: flex;
  flex-direction: column;
}

.displayFlex {
  display: flex !important;
}

.displayNone {
  display: none !important;
}

.darkMode .content,
.darkMode .help,
.darkMode .ReactModal__Content {
  filter: invert(0.9) hue-rotate(180deg) sepia(0.1) saturate(1.05);
}

.darkMode .settingsBox {
  box-shadow: 2px 3px 8px rgb(255, 255, 255);
}

.darkMode .main {
  background-color: #181d22;
}

.darkMode .main > h1 {
  color: var(--light-text);
}
.darkMode .help img,
.darkMode .page img {
  filter: invert(1) hue-rotate(180deg) saturate(1.1) contrast(1.05);
}

.button {
  font-family: var(--headingFont);
  color: var(--light-text);
  background-color: var(--button);
  padding: 0 0.5em;
  font-size: 1.4em;
  font-weight: 400;
  user-select: none;
  height: 1.5em;
  border-radius: 0.25em;
  transition: background-color 0.05s;
  display: inline-block;
}

.button:hover {
  background-color: var(--buttonHover);
  cursor: pointer;
}

.button:active {
  transform: translateY(1px);
}

.darkMode .page {
  background-color: var(--darkBackground);
}

.page {
  text-align: left;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;
  z-index: 2;

  >* {
    flex-grow: 1;
  }
}

.content {
  margin: 0.65em;
  background-color: white;
  border: var(--border);
  border-radius: 0.25em;
  font-family: var(--mainFont);
  font-size: 1.1em;
  padding: 0.65em;
  color: var(--text);
  overflow: auto;
}

@media (max-width: 605px), (max-height: 465px) {
  .content {
    margin: 0.3em;
  }
}

.MuiPaper-root {
  box-shadow: none !important;
  padding-top: 0.25em;
  padding-left: 0.8em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.MuiTableContainer-root {
  align-self: flex-start !important;
  overflow: hidden !important;
  padding-bottom: 100px;
  height: 45%;
  flex-grow: 1;
}

.MuiToolbar-root,
.MuiTableContainer-root {
  max-width: calc(100% - 0.5em) !important;
}

.MuiTableCell-root,
.MuiTypography-root {
  font-family: var(--mainFont) !important;
}

.MuiTableCell-root {
  padding: 0.4em 0.5em !important;
  text-align: center !important;
}

.MuiTableCell-root > div {
  margin: 0 auto;
}

.MuiTableCell-root > input,
.MuiTableCell-root > select {
  font-size: 1.05em;
}

.Mui-TableHeadCell-Content-Labels > div[title="Actions"] {
  display: none;
}

.Mui-TableHeadCell-Content-Labels {
  font-size: 1.1em;
}

.Mui-TableHeadCell-Content {
  justify-content: center !important;
}

.MuiToolbar-root > .MuiBox-root,
.MuiTablePagination-root {
  margin: 0 !important;
}

.MuiTablePagination-root {
  width: 150px;
  height: 56px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.MuiTablePagination-root > div {
  padding: 0;
}

.MuiToolbar-gutters {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: flex-start !important;
  min-height: 0 !important;
}

.MuiToolbar-root > .MuiBox-root {
  position: relative;
  width: 100px;
}

.Mui-ToolbarDropZone,
.MuiCollapse-root {
  display: none !important;
}

.rdw-editor-main {
  height: auto !important;
  padding: 0 0.5em;
}

.rdw-editor-toolbar {
  border-radius: 0.5em 0.5em 0 0 !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 0 !important;
}

.backgroundFade {
  background-color: transparent !important;
}

.nameLbl {
  transition: background-color 0.5s ease-in-out;
}

.zoomBtnSelected {
  background-color: rgb(240, 240, 240) !important;
  border-color: rgb(240, 240, 240) !important;
  transform: translateY(1px);
}

.buttonLink {
  font-size: 1.1em;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  color: var(--links); /* or any other link color */
  cursor: pointer; /* add this to make it look clickable */
}

.LinkedIn {
  background-image: url("./Images/linkedin.png");
  background-position: center;
  background-repeat: no-repeat;
}

.Research-Gate {
  background-image: url("./Images/researchgate.png");
  background-position: center;
  background-repeat: no-repeat;
}

.Facebook {
  background-image: url("./Images/facebook.png");
  background-position: center;
  background-repeat: no-repeat;
}

.Twitter {
  background-image: url("./Images/twitter.png");
  background-position: center;
  background-repeat: no-repeat;
}

.batteryVolts {
  width: 1.15em;
  height: 1.15em;
  padding-right: 0.05em;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  background-image: url("./Images/Battery.png");
}

.batteryIndicator {
  font-size: 0.9em;
  font-weight: 500;
  filter: contrast(0.3) sepia(1) saturate(8) hue-rotate(-170deg)
    brightness(0.65);
  display: flex;
  padding-right: 0em;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

a:hover {
  color: #006dda;
}

/*
This is for the MarkerClusterGroup
*/
.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

/* To solve Next.js issues source from https://github.com/Leaflet/Leaflet.markercluster/blob/master/dist/MarkerCluster.css */
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

#sampleDropdownParent {
  box-shadow: none !important;
}

#sampleDropdown {
  border-radius: 0;
}

#sampleResults {
  border-bottom: 1px solid rgb(108, 108, 108);
}

#sampleResults > div:last-child {
  border-bottom: none;
}
.tutorialBtn {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  padding: 0 !important;
}

.tutorialBtn a {
  font-size: 0.94em;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 0.5em;
  background-color: rgb(236 245 255);
  padding: 2px 0.5em;
  padding-left: 0.4em;
  border: var(--border);
  text-decoration: none;
  margin-bottom: 3px;
  padding: 0.25em 0.75em;
}

.tutorialBtn a:hover {
  background-color: rgb(222, 237, 255);
  border: 1px solid #a0a0a0;
  color: var(--links) !important;
}

.tutorialBtn a > i {
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-1px, 0px) !important;
  filter: invert(48%) sepia(13%) saturate(3157%) hue-rotate(165deg)
    brightness(68%);
}

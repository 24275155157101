.container {
  width: 100%;
  height: calc(100% - var(--menuHeight) - 22px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: var(--menuHeight);
  background-color: #e8eaed;
}

.headerBar {
  padding-left: 1em;
  height: 3em;
  flex-shrink: 0;
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(63, 63, 63, 0.433);
  position: fixed;
  width: 100%;
}

@media (max-width: 750px) {
  .headerBar i {
    display: block !important;
  }
  .menu {
    position: absolute;
    border-radius: 0 0 1em 1em;
    box-shadow: 8px 0px 16px rgba(63, 63, 63, 0.433);
    z-index: 1;
    height: calc(100% - var(--menuHeight) - 22px - 48px);
  }
}

.headerBar i {
  background-image: url("../Images/DropdownArrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  background-size: 50%;
  opacity: 80%;
  background-position: center;
  height: 2.5em;
  width: 2em;
  display: none;
  flex-shrink: 0;
  margin-right: 0.5em;
  cursor: pointer;
}

.headerBar span {
  color: rgb(117, 117, 117);
  font-size: 1.4em;
  line-height: 210%;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainArea {
  padding-top: 48px;
  width: 100%;
  display: flex;
  flex-basis: 3em;
  flex-grow: 1;
  box-sizing: border-box;
  height: calc(100% - var(--menuHeight) - 100px - 48px);
}

.menu {
  width: 250px;
  flex-shrink: 0;
  background-color: white;
  border-right: var(--border);
  overflow: auto;
  padding-bottom: 0.8em;
  box-sizing: border-box;
}

.content {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.925em;
}

.content > div > h1 {
  font-size: 1.75em;
  font-weight: 400;
  margin-top: 0;
}

.content pre {
  display: inline-block;
  font-size: 1.15em;
  background-color: rgb(224, 224, 224);
  border-radius: 0.25em;
  padding: 0.1em 0.25em;
  margin: 0;
}

.content a {
  color: #075ce1;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  display: inline-block;
}

.content a:hover {
  color: #1e90ff;
  cursor: pointer;
}

.content > div > img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.content > div > p:last-child {
  margin-bottom: 0;
}

.content > div > p {
  font-size: 1.25em;
  font-family: var(--mainFont);
}

.content ul {
  margin-top: 0;
  list-style: square;
}

.content p > h3 {
  margin-top: 1.25em;
  margin-bottom: 0.5em;
}

.content ul > li {
  margin: 0.5em;
}

.content ul > li:first-child {
  margin-top: 0;
}

.content > div {
  margin: 1.25em;
  padding: 1.5em;
  background-color: white;
  border: var(--border);
  border-radius: 0.5em;
  width: 80%;
  max-width: 1000px;
  flex-grow: 1;
}

@media (max-width: 605px), (max-height: 465px) {
  .content > div {
    width: calc(100% - 0.6rem);
    box-sizing: border-box;
    margin: 0.3rem;
    padding: 1em;
  }
}

.pageSearch {
  padding: 1em;
  border-bottom: var(--border);
  border-width: 1px;
}

.pageSearch > div {
  height: 2em;
  padding-left: 0em;
  background-color: rgb(238, 238, 238);
  border-radius: 0.25em;
  border: none;
  display: flex;
}

.pageSearch i {
  display: flex;
  align-items: center;
  padding: 0em 0.6em;
  color: rgb(47, 47, 47);
}

.pageSearch > div > input {
  font-size: 1.05em;
  border: none;
  background-color: transparent;
  min-width: 0;
}

.menuSection {
  padding: 0em 1em;
}

.chapterHeader:first-child {
  border: none;
  margin-top: 0;
}

.chapterHeader {
  font-weight: 500;
  font-size: 1.1em;
  border-top: var(--border);
  border-width: 1px;
  padding-top: 0.5em;
  margin-top: 0.5em;
}

.pageLink {
  font-family: var(--mainFont);
  margin: 0.3em 0;
  cursor: pointer;
}

.pageLink:hover {
  text-decoration: underline;
}

.table {
  width: auto;
}

.table td,
.table th {
  border: 1px solid black;
  font-family: var(--mainFont);
  font-size: 1em;
  padding: 0.25em;
  width: 1%;
  white-space: nowrap;
}

b {
  font-weight: 700;
}

code {
  font-size: 1.25em;
}

i {
  font-style: normal;
  font-weight: 500;
}

.noSearchResults {
  margin-top: 0 !important;
  max-height: 40px;
}

.noSearchResults > span {
  font-size: 1.25em;
}

.searchResultTitle {
  display: block;
  width: 80%;
  margin: 1em 0;
}

.searchResult {
  max-height: 95px;
}

.searchResult > a:first-child {
  font-size: 1.3em;
  padding-bottom: 0.5em;
}

.searchResult:not(:first-child) {
  margin-top: 0 !important;
}

.searchResult > div {
  height: 60px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.signInInvitation {
  position: fixed;
  z-index: 3;
  bottom: 1rem;
  right: 1rem;
  transform: translateX(calc(100% + 1rem));
  background-color: #fff;
  border-radius: 0.2rem;
  border: var(--border);
  padding: 2rem 1rem;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  animation: enterScreen 0.3s ease-in-out 2.5s forwards;
}

.signInInvitation h1 {
  font-size: 1.5rem;
}

.signInInvitation p {
  line-height: 2rem;
}

@keyframes enterScreen {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

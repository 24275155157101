.newFolder {
  display: inline;
  position: relative;
}

.cancelNewFolder {
  height: 0.75em !important;
  width: 0.75em !important;
  position: absolute;
  top: 50%;
  transform: translate(calc(-100% - 0.375rem), -50%);
  cursor: pointer;
}

.cancelNewFolder:hover {
  transform: translate(calc(-100% - 0.375rem), -50%) scale(1.05);
}

.newFolder:not(:hover)>.cancelNewFolder {
  display: none;
}

.slash {
  margin: 0 0.25rem;
  font-weight: 600;
}

.location>select,
.location input {
  padding: 0.25rem 0.375rem;
  border-radius: 0.25rem;
  border: 1px solid rgb(221, 221, 221);
}

.location>select {
  cursor: pointer;
}

.location>select:focus,
.location input:focus {
  outline: none;
}
.graphPage {
  gap: 0.65rem;
  padding: 0.65rem;
  box-sizing: border-box;
}

@media (max-width: 605px), (max-height: 465px) {
  .graphPage {
    gap: 0.3em;
    padding: 0.3em;
  }
}

.graphPage > * {
  flex: 1;
}

/* Portrait Mode (Vertical) */
@media (max-aspect-ratio: 15/14) {
  .graphPage {
    flex-direction: column;
  }
}

/* Landscape Mode (Horizontal) */
@media (min-aspect-ratio: 15/14) {
  .graphPage {
    flex-direction: row;
  }
}
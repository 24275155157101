.isoTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.aboutThresholdsISO {
  width: 25px;
  height: 25px;
  background-image: url('../Images/HelpIcon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: contrast(0.3);
  cursor: pointer;
}

.aboutThresholdsISO:hover {
  filter: contrast(0.6);
}

.table {
  display: grid;
  gap: 1px;
  background-color: rgb(142, 142, 142);
  overflow-y: auto;
  border: rgb(142, 142, 142) 1px solid;
  max-height: 12.5rem;

  input, select {
    font-size: inherit;
    border: none;
  }

  *:has(input[type="color"]) {
    padding: 0;
  }

  input[type="color"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.noResults {
  grid-column: span 6;
}

.table > * {
  background-color: white;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
}

.tableTitle {
  font-weight: 600;
  background-color: rgb(245, 245, 245);
  position: sticky;
  top: 0;
  border-bottom: rgb(142, 142, 142) 1px solid;
  margin-bottom: -1px;
  z-index: 1;
}

*:has(.actionIcon) {
  padding: 0;
  justify-content: space-around !important;
}

.actionIcon {
  width: calc(1.5rem - 1px);
  height: calc(1.5rem - 1px);
  cursor: pointer;
}

.newThresholdWrapper {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}

.newThreshold {
  background-color: rgb(241, 241, 241);
  padding: 0.3em 0.4em;
  font-weight: 450;
  color: rgb(50, 50, 50);
  text-align: center;
  border-radius: 0.25em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-family: var(--headingFont);
}

.newThreshold > div {
  background-image: url("../Images/+.png");
  background-size: contain;
  width: 0.8em;
  margin-right: 0.4em;
  height: 0.8em;
  transform: translateY(0.2em);
}

.newThreshold:hover {
  background-color: rgb(209, 209, 209);
}

.error {
  color: red;
  font-size: 0.8rem;
}

.graphHeaderMenu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.3rem 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.346);
  position: relative;
}

.titleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title {
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
}

.title > span {
  height: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  font-size: 1.4em;
  font-family: var(--headingFont);
}

@media (max-width: 470px) {
  .title > span {
    height: 30px;
    width: 162px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

@media (max-width: 605px), (max-height: 465px) {
  .title {
    font-size: 0.9em;
  }
  .graphHeaderMenu {
    padding: 5px 5px 5px 5px;
  }
}

.graphContainer {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  width: 100%;
  margin: 0 !important;

  > graph {
    flex: 1;
  }
}

/* Header (buttons) */
.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.mobileSettings {
  background-image: url("../Images/MoreDetails.png");
  background-size: contain;
}

.mobileOptionsContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: var(--primary);
}

.optionsHeader > span {
  color: white;
  font-weight: 600;
  font-size: 1.5em;
  display: flex;
  flex-grow: 1;
}

.optionsHeader {
  height: 75px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.9em;
  box-sizing: border-box;
}

.exitMobileOptions::after {
  background-image: url("../Images/X_white.png");
  background-size: 42%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  content: "";
  filter: brightness(0.95);
}

.exitMobileOptions {
  aspect-ratio: 1;
  height: 100%;
  background-color: #041729;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 1.5em;
  color: rgb(214, 214, 214);
}

.exitMobileOptions:hover {
  filter: contrast(1.1);
}

.optionsContent {
  width: 100%;
  flex-grow: 1;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

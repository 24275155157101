.footer {
  line-height: 1.5em;
  height: 1.5em;
  width: 100%;
  background-color: var(--primary);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  text-align: center;
  font-size: 0.8em;
  color: #506577;
  padding: 0.1em 0;
}

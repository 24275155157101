.popUpWrapper {
  position: relative;
  max-height: 80vh;
  max-width: calc(100vw - 1.5rem);
}

.exit {
  background-color: var(--button);
  color: white;
  width: 1.3em;
  height: 1.3em;
  font-size: 1.4em;
  border-radius: 2em;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  transform: translateY(-1px);
}

.helpIcon {
  background-image: url("../Images/question.png");
  height: 1.5em;
  width: 1.5em;
  flex-shrink: 0;
  margin-top: 0.06em;
  margin-right: 1em;
  margin-left: 1.5em;
  opacity: 1;
  background-size: contain;
  background-color: white;
  border: 0.3em solid white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  filter: contrast(0.75);
  box-sizing: border-box;
  border-radius: 1em;
  cursor: pointer;
}

.helpIcon:hover {
  background-color: rgb(221, 221, 221);
  border: 0.3em solid rgb(221, 221, 221);
}

.exit:hover {
  background-color: var(--buttonHover);
}

.title {
  flex-grow: 1;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topbar {
  min-width: 190px;
  display: flex;
  position: sticky;
  top: 0;
  padding: 1.25em 1.25em 0 1.25em;
  background-color: #ffffff;
  z-index: 1;
  gap: 1em;
}

.content {
  margin-top: 0em;
  font-family: var(--mainFont);
  overflow-x: auto;
  margin: 0 1.25em 1.25em 1.25em;
}

.footer {
  display: flex;
  justify-content: right;
  margin-top: 1em;
  position: sticky;
  padding: 0 1.25em 1.25em 1.25em;
}

@media (max-width: 605px), (max-height: 465px) {
  .topbar {
    padding: 0.75em 0.75em 0 0.75em;
  }
  .content {
    margin: 0 0.75em 0.75em 0.75em;
  }
  .footer {
    padding: 0 0.75em 0.75em 0.75em;
  }
}

.footer > button {
  border: none;
  border-radius: 0.25em;
  margin-left: 0.5em;
  font-size: 1.1em;
  padding: 0em 0.5em;
}

.cancelBtn {
  background-color: #788693 !important;
}

.cancelBtn:hover {
  background-color: #5e6368 !important;
}

.categoryTitle {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.downloadCategories {
  display: grid;
  column-gap: 3rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.downloadCategory {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.downloadModalBtn:first-child {
  margin-right: 0.5em;
}

.downloadModalBtn {
  position: relative;
  display: inline-flex;
  background-color: rgb(232, 232, 232);
  margin-top: 1rem;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.downloadModalBtn:hover {
  background-color: rgb(209, 209, 209);
}

.downloadModalBtn em {
  margin-left: 0.25em;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.6em;
  margin-left: 0.4em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
}

.jsonIcon {
  background-image: url("../Images/JSON.png");
}

.tableIcon {
  background-image: url("../Images/Table.png");
}

.container {
  width: 100%;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .content p {
    width: 100% !important;
  }
}

.title {
  width: 100%;
  font-size: 1.35em;
  font-weight: 700;
  padding-bottom: 0.25em;
  border-bottom: 2px solid #a2a9b1;
}

.content {
  font-size: 1.25em;
}

.content p {
  padding-right: 10px;
}

.content b {
  font-weight: 650;
}

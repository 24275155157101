.thresholdType > span {
  font-weight: 550;
  display: block;
  margin-bottom: 0.2em;
}

.thresholdType label,
.thresholdType input {
  cursor: pointer;
  user-select: none;
}

.thresholdType label:last-child {
  padding-left: 1em;
}

.bandDescription {
  font-size: 0.95em;
  margin-top: 1em;
}

.samplesDropdown {
  margin-top: 0.25em;
  font-size: 1em;
}

.samplesDropdown input {
  width: 100% !important;
  box-sizing: border-box;
  border: 1px solid rgb(124, 124, 124);
  font-size: inherit;
  padding: 1em 0.5em;
  border-bottom: 0;
}

.samplesDropdown > div:first-child {
  border-bottom: 1px solid rgb(120, 120, 120);
}

.sampleLabel {
  flex-grow: 1;
  height: 24px;
  border: 1px solid rgb(128, 128, 128);
  display: flex;
  padding: 0.25em 0.5em;
  color: rgb(52, 52, 52);
  align-items: center;
  border-bottom: 0;
  cursor: pointer;
}

.samplesDropdownBtn {
  width: 30px;
  box-sizing: border-box;
  background-color: rgb(202, 202, 202);
  border: 1px solid rgb(110, 110, 110);
  border-left: 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  flex-shrink: 0;
  user-select: none;
}

.samplesDropdownBtn:hover {
  background-color: rgb(159, 159, 159);
}

.autoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5em;
}

.autoSection > button {
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.autoSection > button:hover {
  background-color: rgb(228, 228, 228);
}

.autoSection > .input {
  flex-grow: 1;
}

.autoSection button,
.autoSection input {
  font-size: 1em;
  box-sizing: border-box;
  border-radius: 0.25em;
  border: 1px solid #7b7b7b;
  padding: 0.2em 0.4em;
}

.autoSection input {
  margin-left: 0.5em;
}

.autoSection > i {
  filter: contrast(0.45) brightness(2);
  font-size: 1.15em;
  padding-left: 0.5em;
}

.bandRow.header {
  height: 1.5em;
  font-weight: 500;
}

.bandRow {
  height: 25px;
  display: flex;
}

.bandRow > div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.deleteCol {
  width: 9%;
  justify-content: flex-start !important;
}
.frequencyCol {
  width: 40%;
  border: 1px solid #6e6e6e;

}
.warningCol,
.dangerCol {
  width: 30%;
  border: 1px solid #6e6e6e;
}
.addCol {
  width: 9%;
  justify-content: flex-end !important;
}

.deleteCol > img {
  width: 55%;
  cursor: pointer;
  filter: invert(0.25) sepia(1) saturate(100) hue-rotate(330deg) brightness(0.8);
}

.deleteCol > img:hover {
  filter: invert(0.25) sepia(1) saturate(100) hue-rotate(330deg) brightness(0.6);
}
.addCol > img {
  width: 58%;
  cursor: pointer;
  filter: invert(0.45) sepia(1) saturate(100) hue-rotate(115deg) brightness(1);
}
.addCol > img:hover {
  filter: invert(0.45) sepia(1) saturate(100) hue-rotate(115deg) brightness(0.7);
}

.bandInput {
  height: 100%;
  width: 100%;
  border-radius: 0em;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-size: 1em;
  text-align: center;
}

.header .warningCol,
.header .dangerCol,
.header .frequencyCol {
  border-radius: 0.5em 0.5em 0 0;
  background-color: #dee2e9;
  border: none;
}

.greyedOut {
  background-color: #c7cdd0;
}

.bandTableContainer {
  overflow: auto;
  max-height: 300px;
}

.reset:hover, .notifications>select:hover {
  background-color: rgb(209, 209, 209);
}

.resetWrapper {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
  align-items: center;
}

.reset {
  background-color: rgb(241, 241, 241);
  padding: 0.3rem 0.4rem;
  font-size: 1rem;
  font-weight: 450;
  color: rgb(50, 50, 50);
  text-align: center;
  border-radius: 0.25em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-family: var(--headingFont);
}

.notifications>select {
  background-color: rgb(241, 241, 241);
  border: none;
  font-size: 0.95rem;
  padding: 0.3rem 0.4rem;
  font-weight: 450;
  color: rgb(50, 50, 50);
  border-radius: 0.25em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-family: var(--headingFont);
}

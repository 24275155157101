@media (max-width: 565px) {
  .customTooltip {
    font-size: 0.8em;
  }
}

.customTooltip {
  border: 1px solid rgb(210, 210, 210);
  padding: 0em 1em;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  position: relative;
}

.customTooltip b {
  font-weight: 600;
}

.customTooltip p {
  margin: 0;
  margin-bottom: 0.3em;
  font-size: 0.95em;
}

.customTooltip p:first-child {
  margin-top: 0.3em;
}
.noData {
  position: relative;
  margin: 0 auto;
  top: -1em;
  line-height: 2em;
  vertical-align: middle;
  width: 300px;
  font-size: 1em;
  text-align: center;
}

.noData > b {
  margin-bottom: 0.5em;
}

.noData > button {
  display: block;
  margin: 0 auto;
  margin-top: 1.5em;
  font-size: 0.95em;
  cursor: pointer;
  padding: 0.25em 0.75em;
}

.noData > img {
  transform: translateY(5px);
}

@media (max-width: 565px) {
  .noData {
    font-size: 0.8em !important;
  }
}

.tips {
  background-image: url("../Images/Lightbulb.png");
  background-size: contain;
}

.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.thresholdSettingsBtn:hover {
  background-color: #e0e0e0;
}

.thresholdSettingsBtn {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  cursor: pointer;

  div {
    background-image: url("../Images/SettingsIcon.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  }
}

.faultsAnalysisDropdown {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  top: calc(100% + 0.25rem);
  left: -100%;
  transform: translateX(25%);
  background-color: rgb(238 244 246);
  border: 1px solid grey;
  border-radius: 0.25rem;
  box-shadow: 0.5px 2px 3px rgba(0, 0, 0, 0.3);
  font-size: 0.95rem;
  font-weight: 500;
  z-index: 10;
}

@media (max-width: 605px), (max-height: 465px) {
  .faultsAnalysisDropdown {
    gap: 0.25rem;
    left: 0;
    transform: translateX(0);
  }  
}

.harmonicsSelector {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 550;
  white-space: nowrap;
  margin-left: 0.5rem;

  button {
    border: 1px solid #cdcdcd;
    background-color: #eaeaea;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    font-weight: 550;
    font-size: 0.9rem;
  }

  button:first-child {
    margin-left: 0.5rem;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  button:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  
  input {
    width: 1.5rem;
    border: 1px solid #cdcdcd;
    border-left: none;
    border-right: none;
    font-size: 0.9rem;
    padding-bottom: 2px;
    padding-left: 5px;
  }
}

.mobileTitleButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileTitleButton:hover {
  filter: contrast(1.5);
}
.mobileTitleButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

.customFaultDropdown {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  top: calc(100% + 0.25rem);
  left: 50%;
  transform: translateX(-50%);
  background-color: #eaeaea;
  border: 1px solid grey;
  border-radius: 0.25rem;
  box-shadow: 0.5px 2px 3px rgba(0, 0, 0, 0.3);
  font-size: 0.95rem;
  font-weight: 500;
  z-index: 10;
}

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;

  label {
    white-space: nowrap;
  }
  input {
    width: 5ch;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
}

.btn>i {
  margin-right: 0.5em;
  font-size: 0.95em;
}

.btn {
  display: block !important;
  text-align: center;
  margin-bottom: 0.8em;
  padding: 0.2em 1em !important;
  height: auto !important;
  background-color: rgb(48, 92, 130) !important
}

.btn:hover {
  background-color: #1f4f79 !important;
}

.themePicker {
  padding-right: 0;
  background-color: transparent;
  font-size: 1.4em;
  color: white;
}

.themePicker>i {
  margin-left: 0.5em;
  font-size: 1.1em;
  border: 5px solid rgb(98, 98, 98);
  border-radius: 50%;
  cursor: pointer;
}

.username {
color: white;
font-size: 1.4em;
padding-bottom: 0.7em;
}
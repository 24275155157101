.bothWrapper {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  height: 40px;
}

.wrapper {
  position: relative;
}

/* Dropdown Button */
.button {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 0.5em;

  display: flex;
  font-size: 1.1em;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  gap: 0.5rem;
  padding-inline: 0.5rem;

  user-select: none;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.button:active {
  transform: translateY(1px);
}

.button > div {
  width: 1.4em;
  height: 1.4em;
}

.downArrow {
  margin-left: auto;
  background-image: url("../Images/DropdownArrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 0.75em !important;
  transform: translateY(1px);
  filter: contrast(0.9);
}

/*****************************************************************/

/* Dropdown Menu */
.dropdown {
  min-width: calc(100% - 2px);
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1px;
  top: calc(100% + 0.25rem);
  right: 0;
  background-color: grey;
  border: 1px solid grey;
  border-radius: 0.5rem;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.3);
  font-size: 0.95em;
  z-index: 10;

  :first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  :last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.dropdown input {
  background-color: white;
  border: none;
  font-size: inherit;
  padding: 0.5em 0.8em;
}

.result {
  background-color: #eaeaea;
  padding: 0.5em 0.8em;
  cursor: pointer;
  white-space: nowrap;
}

.result:hover {
  background-color: rgb(204, 204, 204);
}

.mobileButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileButton:hover {
  filter: contrast(1.5);
}
.mobileButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

.mobileResult {
  background-color:#00223f;
  color: white;
}
.mobileResult:hover {
  background-color: #003366;
}
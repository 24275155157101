:root {
  --menuLines: 2px solid rgb(31, 51, 68);
}

.container {
  background-color: var(--primary);
  height: var(--menuHeight);
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.65);
  z-index: 2;
}

.dropdown {
  position: fixed;
  z-index: 3;
  right: 0;
  top: 3.5em;
  padding: 1em;
  text-align: right;
  background-color: var(--darkerColor);
  border-radius: 0 0 0 1em;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
}

/* Logo*/

.logoSection {
  padding-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: var(--menuLines);
}

.logoSection:hover {
  background-color: rgb(14, 28, 42);
}

.logo {
  background-image: url(../Images/Logo.png);
  width: 60px;
  height: 70%;
  margin-left: 0.1em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logoSection > span {
  padding-right: 1em;
  display: flex;
  font-weight: 500;
  line-height: var(--menuHeight);
  display: block;
  height: 100%;
  color: var(--light-text);
  font-family: var(--logoFont);
  font-size: 1.5em;
  user-select: none;
}

.tabsContent {
  width: 100%;
  background-color: var(--primary);
  flex-grow: 1;
}

.tabsHeader > span {
  color: white;
  font-weight: 600;
  font-size: 1.75em;
  display: flex;
  flex-grow: 1;
}

.tabsHeaderLogo {
  width: 50px;
  height: 80px;
}

.tabsHeaderLogo::after {
  content: "";
  background-image: url("../Images/SoundWave.png");
  background-size: 50%;
  filter: invert(1);
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  height: 100%;
  width: 100%;
}

.tabsHeader {
  height: 75px;
  width: 100%;
  flex-shrink: 0;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.9em;
  box-sizing: border-box;
}

.exitMobileTabs::after {
  background-image: url("../Images/X_white.png");
  background-size: 42%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  content: "";
  filter: brightness(0.95);
}

.exitMobileTabs {
  aspect-ratio: 1;
  height: 100%;
  background-color: #041729;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 1.5em;
  color: rgb(214, 214, 214);
}

.exitMobileTabs:hover {
  filter: contrast(1.1);
}

.mobileTabsContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.mobileTabs {
  display: none;
}

@media only screen and (max-width: 800px) {
  .logoSection > span {
    display: none !important;
  }
  .tabsSection {
    display: none;
    min-width: 60px;
    max-width: 0;
  }
  .mobileTabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
    height: var(--menuHeight);
    overflow: none;
  }
  .devicesTabs {
    margin-right: 0 !important;
    border-right: 0 !important;
  }
  .devicesTabs::before {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 1.7em;
  }
  .analysisTabsNone {
    width: 100%;
    height: 50px;
    font-weight: 500;
    font-size: 1.5em;
    color: white;
    padding: 0.5em 1em;
  }
  .analysisTabs {
    border-right: 0 !important;
    margin: 0 !important;
    width: 45px !important;
    display: inline-block;
  }
  .analysisContainer > div {
    display: inline-block !important;
    border: none !important;
    margin: 0px !important;
    width: 40px !important;
  }
  .analysisContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 50px !important;
    font-weight: 500;
  }
  .analysisTabs::after {
    background-image: url("../Images/SoundWave.png");
  }
  .mobileTabs div::after {
    content: "";
    filter: invert(1);
    background-size: 50%;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 100%;
    width: 100%;
  }

  .mobileTabs div {
    flex-shrink: 0;
    border-left: var(--menuLines);
    border-right: var(--menuLines);
    width: 60px;
    height: 100%;
    background-color: var(--button);
    cursor: pointer;
    margin-right: 0.5em;
  }

  .mobileTabs div:hover > div,
  .mobileTabs div:hover {
    background-color: var(--buttonHover);
  }
}

/* Tabs */

.tabsSection {
  flex-grow: 1;
  border-right: var(--menuLines);
  overflow: auto;
  max-width: 100% - 100px;
}

/* Profile Section */

.profileSection {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0.25em 0;
}

.profileSection:hover {
  background-color: rgb(14, 28, 42);
}

.profileSection > img {
  height: 43px;
  width: 43px;
  border-radius: 10em;
  margin-left: 1.1em;
  margin-right: 0.25em;
  background-color: rgb(84, 81, 126);
}

.profileSection > i {
  color: #d7d7d7;
  font-size: 1.1em;
  margin-right: 1em;
}

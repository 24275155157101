.page {
  display: flex;
  margin-top: var(--menuHeight);
  width: 100%;
  max-width: calc(850px + 680px + 1em);
  margin-inline: auto;
}

.page > * {
  box-sizing: border-box;
  margin-bottom: 1em !important;
  padding: 0 !important;
  padding-top: 1em;
  max-height: calc(100vh - 115px);
}

.filebrowser {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 1.3em);
  max-width: 850px;
}

.filebrowser > div {
  font-family: var(--mainFont);
}

.readme {
  flex: 1;
  box-sizing: border-box;
  padding: 1.25em !important;
  margin-left: 0 !important;
  width: calc(100% - 1.3em);
  max-width: 850px;
}

@media (max-aspect-ratio: 15/14) {
  .page {
    flex-direction: column;
    max-height: calc(100vh - 115px);
    align-items: center;
  }
  .filebrowser {
    margin-right: 0.65em !important;
  }
  .readme {
    margin-top: 0 !important;
    margin-left: 0.65em !important;
  }
}

.downloadModalBtn {
  position: relative;
  display: flex;
  background-color: rgb(232, 232, 232);
  margin-bottom: 0.8em;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.downloadModalBtn:hover {
  background-color: rgb(209, 209, 209);
}

.downloadModalBtn:first-child {
  margin-top: 0.5em;
}

.downloadModalBtn:last-child {
  margin-bottom: 0;
}

.downloadModalBtn em {
  margin-left: 0.25em;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.6em;
  margin-left: 0.4em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
}

.jsonIcon {
  background-image: url("../Images/JSON.png");
}

.tableIcon {
  background-image: url("../Images/Table.png");
}

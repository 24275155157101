.legendDropdown {
  position: relative;
}

.legendDropdownButton {
  display: flex;
  align-items: center;
  border: 1px solid #cdcdcd;
  background-color: #eaeaea;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.1rem 0.5rem;
  font-weight: 550;
  white-space: nowrap;
}

.legendDropdownArrow {
  background-image: url("../Images/DropdownArrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  filter: contrast(0.75);
  height: 15px;
  width: 15px;
  margin-left: 0.5rem;
}

.legendDropdownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1px;
  top: calc(100% + 0.25rem);
  right: 0;
  background-color: grey;
  border: 1px solid grey;
  border-radius: 0.25rem;
  box-shadow: 0.5px 2px 3px rgba(0, 0, 0, 0.3);
  font-size: 0.95rem;
  font-weight: 500;
  min-width: calc(100% - 2px);
  z-index: 10;

  :first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  :last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .dropdownOption {
    display: flex;
    gap: 0.25rem;
    background-color: #fefefe;
    padding: 0.25rem;
    cursor: pointer;
  }
}

@media (max-width: 605px), (max-height: 465px) {
  .legendDropdown * {
    font-size: 0.9rem;
  }

  .legendDropdownMenu {
    font-size: 0.85rem;

    .dropdownOption {
      padding: 0.15rem;
    }
  }
}

.disabled {
  cursor: not-allowed;
  background-color: #cdcdcd;
}
.wrapper {
  position: relative;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 0.15rem;
  margin-left: 0.5rem;
}

.share {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(27, 41, 53);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  z-index: 1;
  transition: 250ms;
}

.share > img {
  width: 1rem;
  height: 1rem;
  filter: invert(100%);
  transform: translateX(-0.05rem);
}

.share:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.modal {
  height: 100%;
  display: inline-flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  font-size: 0.75rem;
  overflow: hidden;
  z-index: 999;
}

.modal > .link {
  padding: 0.125rem 0.5rem;
  background-color: rgb(240, 240, 240);
  color: rgb(100, 100, 100);
  border-radius: 0.2rem 0 0 0.2rem;
  overflow: hidden;
}

.modal > button {
  background-color: rgb(50, 100, 150);
  color: white;
  font-size: 0.75rem;
  border: none;
  border-radius: 0 0.2rem 0.2rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal > button:hover {
  background-color: rgb(40, 80, 120);
}

.modal > button > img {
  width: 0.9rem;
  height: 0.9rem;
  filter: invert(100%);
}

.container {
  margin-top: calc(var(--menuHeight) + 1em) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.25em;
  width: 100%;
}

h2 {
  padding-bottom: 0.5em;
}

.container:after {
  position: fixed;
  top: 0;
  content: '';
  height: 100%;
  filter: saturate(1.2) opacity(0.4);
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-image: url('../Images/HomeBackground.webp');
}
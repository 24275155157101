.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container::after {
  position: fixed;
  top: 0;
  content: "";
  height: 100%;
  filter: saturate(1.2) opacity(0.4);
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-image: url("../Images/HomeBackground.webp");
}

.container > div {
  width: 300px;
  background-color: rgb(235, 235, 235);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 2em;
  margin-top: -8em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container h1 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  margin-top: 0;
  margin-bottom: 1em;
}

.inputBox {
  width: 100%;
  margin-bottom: 1em;
}

.inputBox > div {
  padding-bottom: 0.25em;
  font-size: 1.25em;
  font-weight: 450;
}

.inputBox > input {
  width: calc(99% - 0.25em);
  height: 2em;
  font-size: 1.2em;
  padding: 0;
  padding-left: 0.25em;
  border-radius: 0.5em;
  border: 2px solid rgb(159, 159, 159);
}

.container button {
  font-size: 1.25em;
  padding: 0.4em 0.5em;
  background-color: var(--button);
  color: rgb(250, 250, 250);
  font-weight: 500;
  border: none;
  border-radius: 0.25em;
  margin-top: 0.75em;
  cursor: pointer;
}

.container button:hover {
  background-color: var(--buttonHover);
}

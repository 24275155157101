.graph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding-inline: 1rem;
  height: 100%;
  overflow: hidden;
}

.legend {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding-block: 0.5rem;
}

.faultLegend {
  position: absolute;
  right: 2rem;
  top: calc(100% + 0.25rem);
  z-index: 1;
}

.menuBtns {
  display: flex;
  gap: 1.5rem;
}

.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.question {
  background-image: url("../Images/question.png");
  border-width: 9px;
  background-size: contain;
}

.download {
  background-image: url("../Images/SaveIcon.png");
  background-size: contain;
}


.zoomOut {
  background-image: url("../Images/ZoomOut.png");
  background-size: contain;
}

.zoomIn {
  background-image: url("../Images/ZoomIn.png");
  background-size: contain;
}

.mobileTitleButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileTitleButton:hover {
  filter: contrast(1.5);
}
.mobileTitleButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

.downloadModalBtn {
  display: flex;
  align-items: center;
  background-color: rgb(232, 232, 232);
  font-size: 1.2em;
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.downloadModalBtn em {
  margin-left: 0.25em;
}

.downloadModalBtn:hover {
  background-color: rgb(209, 209, 209);
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.6em;
  margin-left: 0.2em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
}

.svgIcon {
  background-image: url("../Images/SVG.png");
}

.graphIcon {
  background-image: url("../Images/SoundWave.png");
}

.tableIcon {
  background-image: url("../Images/Table.png");
}

.jsonIcon {
  background-image: url("../Images/JSON.png");
}

@media (max-width: 605px), (max-height: 465px) {
  .graph {
    padding-inline: 0.15rem;
  }  

  .legend {
    gap: 0.5rem;
    padding-block: 0.5rem;
    font-size: 0.8em;
  }

  .faultLegend * {
    font-size: 0.8em !important;
  }
}
.info {
  height: 2.25rem;
  background-color: rgb(240, 240, 240);
  border-radius: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 0 0.5rem;
}

.info > img {
  width: 1.35rem;
  height: 1.35rem;
}

.info > span {
  font-size: 1rem;
}

.info.button {
  cursor: pointer;
}
.info.button:hover {
  background-color: rgb(230, 230, 230);
}

.tag {
  background-color: rgb(210, 232, 255);
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.tag > img {
  height: 0.8rem !important;
  width: 0.8rem !important;
  padding-left: 0.25rem;
}

.machineSettings {
  display: grid;
  gap: 0.25rem;
  min-width: 15rem;
}

.machineSettings h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

/****************/
/**** Modals ****/
/****************/

.actionButton {
  flex: 1;
  display: inline-flex;
  align-items: center;
  background-color: rgb(232, 232, 232);
  padding: 0.5em;
  border-radius: 0.35em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.actionButton:hover {
  background-color: rgb(209, 209, 209);
}

.actionButton em {
  margin-left: 0.25em;
}

.actionButtons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.6em;
  margin-left: 0.4em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
}

.downloadIcon {
  background-image: url("../Images/DownloadIcon.png");
}

.saveIcon {
  background-image: url("../Images/AddIcon.png");
}

.infos {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.socialMedia {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.socialMediaIcon {
  width: 20px;
  height: 20px;
  background-size: 100%;
}

.researcherDetail {
  margin-top: 1rem;
  font-weight: 600;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .preview.hasResearcherDetails {
    min-width: 15rem;
    max-width: 30rem;
  }
}
.preview:has(.researcherDetails) {
  min-width: 15rem;
  max-width: 30rem;
}

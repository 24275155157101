.title {
  display: flex;
}

.title > h2 {
  font-size: 1.125rem;
  font-weight: 600;
}

.title > .tooltip {
  margin-left: 0.5rem;
}

.searchResults {
  position: relative;
  display: grid;
  gap: 1px;
  grid-template-columns: 0.25fr 1fr 0.5fr 1.5fr;
  background-color: #ddd;
  border-radius: 0.2rem;
  border: 0.1rem solid #ddd;
  overflow-y: auto;
  max-height: 20rem;
}

.noResults {
  grid-column: span 4;
}

.searchResults > * {
  background-color: white;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .tableCell.hasCheckbox,
  .tableTitle.hasCheckbox {
    justify-content: center;
  }
}
.tableCell:has(input[type="checkbox"]),
.tableTitle:has(input[type="checkbox"]) {
  justify-content: center;
}

.tableTitle {
  font-weight: 600;
  background-color: rgb(245, 245, 245);
  position: sticky;
  top: 0;
  border-bottom: #ddd 1px solid;
  margin-bottom: -1px;
}

.filters {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.filter {
  height: 2.25rem;
  background-color: rgb(240, 240, 240);
  border-radius: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  display: inline-flex;
  gap: 5px;
  align-items: center;
  padding: 0 0.5rem;
}

.filter > span {
  font-weight: 600;
}

.subFilter {
  display: flex;
  align-items: center;
}

.subFilter > span {
  margin-right: 5px;
}

.subFilter > select,
.subFilter > input {
  border: 1px solid rgb(130, 130, 130);
  padding: 0.2em 0.25em;
  font: inherit;
  font-size: 0.75rem;
  border-radius: 0.5em;
  box-sizing: border-box;
}

.subFilter > select {
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: none;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .subFilter.hasSelect > input {
    justify-content: center;
  }
}
.subFilter:has(select) > input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.subFilter > select {
  cursor: pointer;
}

.subFilter > select:focus,
.subFilter input:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}

.tag {
  background-color: rgb(210, 232, 255);
  font-weight: 600;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.container {
  margin: 1em 0;
  border: 2px solid #c8ccd1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  width: 25%;
  min-height: 75px;
  background-color: #f8f9fa;
  min-width: 150px;
}

@media (max-width: 1000px) {
  .container {
    width: 100%;
  }
}

.container h1 {
  font-size: 1.35em;
  padding-bottom: 0.25em;
}

.container a {
  font-size: 1.05em;
  padding: 0.25em 0;
}

.container > div {
  width: 100%;
  padding: 0.75em 1em;
  padding-top: 0;
  box-sizing: border-box;
  font-size: 0.85em;
  font-weight: 450;
}

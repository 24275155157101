/* Header buttons */

.menuBtns {
  display: flex;
  gap: 1.5rem;
}

.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.question {
  background-image: url("../Images/question.png");
  border-width: 9px;
  background-size: contain;
}

.close {
  background-image: url("../Images/X_white.png");
  background-size: contain;
}

.close.mobile {
  filter: invert(0) !important;
}

.close:not(.mobile) {
  background-color: var(--button);
  border-color: var(--button);
}

.close:not(.mobile):hover {
  background-color: rgb(23, 67, 106);
  border-color: rgb(23, 67, 106);
}

/* ************************* */

/* Graph manager row */
.textSetting {
  font-size: 0.9em;
  width: 80px;
}

.graphType {
  font-size: 0.9em;
}

.colorPickerBtn {
  height: 1.2em;
  width: 2.75em;
  border-radius: 1em;
  border: 2px solid rgb(66, 66, 66);
  cursor: pointer;
}

.colorPickerBtn:hover {
  border-color: black;
}

/* Sample selector */

.samplesDropdown {
  width: 270px;
}

.samplesDropdown input {
  width: 100% !important;
  box-sizing: border-box;
  border: 1px solid rgb(124, 124, 124);
  font-size: 0.95em;
  padding-inline: 0.8em;
  border-bottom: 0;
}

.samplesDropdown > div:first-child {
  border-bottom: 1px solid rgb(120, 120, 120);
}

.sampleLabel {
  font-size: 0.95em;
  flex-grow: 1;
  height: 24px;
  border: 1px solid rgb(128, 128, 128);
  display: flex;
  padding-left: 0.75em;
  color: rgb(52, 52, 52);
  align-items: center;
  border-bottom: 0;
  cursor: pointer;
}

.samplesDropdownBtn {
  width: 30px;
  box-sizing: border-box;
  background-color: rgb(202, 202, 202);
  border: 1px solid rgb(110, 110, 110);
  border-left: 0;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  flex-shrink: 0;
  user-select: none;
}

.samplesDropdownBtn:hover {
  background-color: rgb(159, 159, 159);
}

/* Sample selector modal */ 

.sampleResults > div:last-child {
  border-radius: 0em 0em 0.25em 0.25em;
}

.sampleResults {
  font-size: 0.95em;
  overflow: auto;
  border: 1px solid rgb(148, 148, 148);
  border-top: 0;
  border-bottom: none;
  border-radius: 0 0 0.25em 0.25em;
  width: 100% !important;
}

.sampleResults > div {
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  border-bottom: 1px solid rgb(108, 108, 108);
  cursor: pointer;
  color: black;
  text-align: center;
}

.sampleResults > div:hover {
  background-color: rgb(204, 204, 204);
}

.sampleMaxHeight {
  max-height: 150px !important;
}

.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  width: 1.25em;
  height: 1.25em;
  opacity: 0.8;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.icon:hover {
  opacity: 1;
  cursor: pointer;
  transform: scale(1.075);
}

.viewSample {
  background-image: url("../Images/SoundWave.png");
}

.delete {
  background-image: url("../Images/X.png");
  width: 1.1em !important;
  height: 1.1em !important;
}

/* ************************* */

/* Controls */

.manageTable {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
}

.addGraph {
  background-color: rgb(241, 241, 241);
  padding: 0.3em 0.4em;
  font-weight: 450;
  color: rgb(50, 50, 50);
  text-align: center;
  border-radius: 0.25em;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: var(--headingFont);
}

.addGraph > div {
  background-image: url("../Images/+.png");
  background-size: contain;
  width: 0.8em;
  margin-right: 0.4em;
  height: 0.8em;
  transform: translateY(0.2em);
}

.addGraph:hover {
  background-color: rgb(209, 209, 209);
}

.icons {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.icons > .pages {
  font-size: 0.8rem;
  margin-inline: 0.5rem 1rem;
}

.icons > *:not(.pages, .chooseColumnsModal) {
  height: 2.25rem;
  width: 2.25rem !important;
  border-radius: 100px;
  border: 6px solid white;
  box-sizing: border-box;
  background-size: contain;
  cursor: pointer;
}

.icons > *:not(.pages, .chooseColumnsModal):hover {
  background-color: rgb(200, 200, 200);
  border-color: rgb(200, 200, 200);
}

.previousIcon {
  background-image: url("../Images/LeftIcon.png");
}

.nextIcon {
  background-image: url("../Images/RightIcon.png");
}

.chooseColumns {
  background-image: url("../Images/ColumnsIcon.png");
}

.chooseColumnsModal {
  position: absolute;
  top: calc(2.25rem + 0.5rem);
  right: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  z-index: 10;
  padding: 0.5rem;
  background-color: rgb(241, 241, 241);
  display: grid;
  grid-template-columns: 1fr 0.25fr;
  gap: 0.5rem;
}

@media (max-width: 605px), (max-height: 465px) {
  .chooseColumnsModal {
    gap: 0.4rem;
    font-size: 0.8em;
  }
}

/* ************************* */

/* Table */

.layers,
.noLayers {
  margin: 1rem;
  display: grid;
  overflow-x: auto;
  height: calc(100% - 3.25rem - 52px - 2rem);
  align-content: flex-start;
  text-align: center;
}

.tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(200, 200, 200);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-inline: 0.25rem;
}

.tableTitle {
  font-weight: 700;
}

@media (max-width: 605px), (max-height: 465px) {
  .layers {
    margin: 0.5rem;
  }

  .tableCell {
    padding-bottom: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .tableTitle {
    font-size: 0.9em;
    
  }
}

.mobileTitleButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileTitleButton:hover {
  filter: contrast(1.5);
}
.mobileTitleButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

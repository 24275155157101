.container {
  height: 100%;
  overflow: auto;
  margin-top: var(--menuHeight);
}

.container:after {
  position: fixed;
  top: 0;
  content: "";
  height: 100%;
  filter: saturate(1.2) opacity(0.4);
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-image: url("../Images/HomeBackground.webp");
}

.loginBox {
  text-align: left;
  max-width: 525px;
  padding: 1em !important;
  overflow: unset !important;
  margin-inline: auto !important;
}

@media (max-width: 605px), (max-height: 465px) {
  .container {
    font-size: 0.85rem;
  }

  .loginBox {
    margin-inline: 0.3em !important;
  }
}

.loginBox > h2 {
  font-size: 1.7em !important;
  font-weight: 400;
}

.loginBox > p {
  font-size: 1.15em;
  margin-top: 0;
}

.loginBox:last-child > p {
  margin-bottom: 0;
}

.loginBox:last-child {
  margin-bottom: 2em !important;
}

/* Login with Microsoft and Google */
.loginBtn > i {
  margin-right: 0.5em;
}

.loginBtn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 1.3em !important;
  padding: 0.25em !important;
  width: calc(100% - 0.4em);
  text-align: center;
  height: 2em !important;
  margin-bottom: 0em;
}

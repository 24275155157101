.container {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0.5em 0.7em;
  padding-top: 0.6em;
  box-sizing: border-box;
  position: relative;
  background-color: rgb(240, 240, 240);
}

.section {
  box-sizing: border-box;
  padding: 0.6em 0.65em;
  padding-bottom: 0.5em;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(212, 212, 212);
  border-top: 0;
  position: relative;
}

.section:first-child {
  border-radius: 0.25em 0.25em 0 0;
  border-top: 2px solid rgb(212, 212, 212);
}

.section:last-child {
  border-radius: 0 0 0.25em 0.25em;
}

.header {
  font-weight: 550;
}

.header i {
  color: rgb(141, 141, 141);
  margin-left: 0.5em;
}

.header i:hover {
  color: rgb(87, 87, 87);
}

.inputs {
  display: flex;
  flex-wrap: wrap;
}

.inputs>div {
  width: 200px;
  padding-bottom: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.inputs span {
  display: block;
  padding-right: 0.5em;
}

.inputs input,
.inputs select {
  margin-top: 0.25em;
  width: 170px;
  font-size: 0.9em;
  margin-right: 1em;
}

.collapseArrow {
  background-image: url('../Images/DropdownArrow.png');
  background-size: contain;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  top: 0.6em;
  right: 0.7em;
  filter: contrast(0.6);
  cursor: pointer;
}

.collapseArrow:hover {
  filter: contrast(0.8);
}

.toggle {
  display: inline-block;
  width: 30px;
  height: 15px;
  background: #afafaf;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  margin-left: 0.5em;
  transform: translateY(0.1em);
}

.toggle > div {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 2px;
  transition: left 0.2s;
}

.toggleOn {
  background: rgb(96 197 96);
}

.toggleOn > div {
  left: 15px;
}

.menuBtns {
  display: flex;
  gap: 1.5rem;
}

.headerButton {
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  filter: contrast(0.85);

  border-radius: 50%;
  border: 6px solid white;

  height: 2.25em;
  width: 2.25em;
  box-sizing: border-box;
  
  user-select: none;
  cursor: pointer;
}

.headerButton:hover {
  background-color: rgb(240, 240, 240);
  border-color: rgb(240, 240, 240);
}

.headerButton:active {
  transform: translateY(1px);
}

.question {
  background-image: url("../Images/question.png");
  border-width: 9px;
  background-size: contain;
}

.close {
  background-image: url("../Images/X_white.png");
  background-size: contain;
}

.close.mobile {
  filter: invert(0) !important;
}

.close:not(.mobile) {
  background-color: var(--button);
  border-color: var(--button);
}

.close:not(.mobile):hover {
  background-color: rgb(23, 67, 106);
  border-color: rgb(23, 67, 106);
}

.mobileTitleButton {
  background-color: var(--button);
  font-weight: 550;
  color: rgb(247, 247, 247);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 0.5em;
  align-items: center;
}
.mobileTitleButton:hover {
  filter: contrast(1.5);
}
.mobileTitleButton > div {
  filter: invert(1);
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.4em;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1em 0;
}

@media (max-width: 1000px) {
  .container,
  .innerBox {
    width: 100% !important;
  }
}

.innerBox {
  background-color: #f8f9fa;
  border: 2px solid #c8ccd1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.innerBox > div {
  width: 100%;
  padding: 0.75em 1em;
  padding-top: 0;
  box-sizing: border-box;
  font-size: 0.85em;
  text-align: center;
  font-weight: 450;
}

.innerBox > img {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}

.innerBox > img:hover + .expand {
  opacity: 1;
}

.expand {
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  height: 30px !important;
  width: 30px !important;
  margin: 5px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  background-image: url("../Images/FullView.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

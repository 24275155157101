.invite {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  font-size: 1.1rem;
}

.invitePage {
  margin-top: calc(var(--menuHeight) + 5rem) !important;
  margin-inline: auto;
  background-color: #fff;
  border: var(--border);
  border-radius: 0.25em;
}

.invite > h1 {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 3rem;
}
.invite > h1 > span {
  color: rgb(27, 41, 53);
  font-weight: 700;
}

.invite button {
  border-radius: 0.25em;
  border: 2px solid rgb(50, 100, 150);
  background-color: rgb(50, 100, 150);
  color: white;
  font: inherit;
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  cursor: pointer;
}
.declineBtn {
  margin-left: 1.25rem;
  background-color: #fff !important;
  color: rgb(50, 100, 150) !important;
}

.invite button:hover {
  background-color: rgb(30, 80, 130);
  border-color: rgb(30, 80, 130);
}
.declineBtn:hover {
  border-width: 3px !important;
  padding: calc(0.5rem - 1px) calc(1.25rem - 1px) !important;
}

.container {
  min-width: 400px;
  width: 80%;
  max-width: 800px;
  height: fit-content;
  margin-top: calc(var(--menuHeight) + 1em) !important;
  display: flex;
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: left;
    min-width: 325px;
    max-width: 60vw !important;
  }
  .container > img {
    height: 125px;
    width: 125px;
    border-radius: 50%;
    align-self: center;
    background-color: var(--primary);
    margin-bottom: 1em;
  }
  .content {
    max-width: 60vw !important;
  }
  .main {
    font-size: 1em !important;
  }
}

.photo {
  width: 200px;
  height: 200px;
  padding: 0.5em;
  box-sizing: border-box;
}

.photo > div {
  background-color: rgb(131, 131, 131);
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.content {
  flex-grow: 1;
  height: 100%;
  padding-left: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.main {
  font-weight: 500;
  font-size: 1.25em;
}

.details {
  font-size: 1.1em;
}

.deleteBtn {
  background-color: rgb(255, 174, 174);
  border: 2px solid rgb(255, 117, 117);
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.359);
  font-weight: 500;
  padding: 0.25em;
  width: 225px;
  margin-top: 0.25em;
  cursor: pointer;
  border-radius: 0.25em;
  font-size: 1em;
  text-align: center;
}

.deleteBtn:hover {
  background-color: rgb(255, 139, 139);
}

.confirmModal {
  width: 400px;
  font-size: 1.1em;
}

.institution,
.description,
.socialMedias {
  margin-top: 1rem;
  font-weight: 600;
}
.socialMedias {
  margin-bottom: 1rem;
}

.add {
  display: inline;
  background-image: linear-gradient(#000000, #000000);
  background-size: 0% 0.1rem;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 250ms ease-in-out;
  cursor: pointer;
}

.add:hover {
  background-size: 100% 0.1rem;
}

.institution,
.description,
.socialMedia {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.institution:not(:hover) > .edit,
.description:not(:hover) > .edit,
.socialMedia:not(:hover) > .edit,
.institution:not(:hover) > .delete,
.description:not(:hover) > .delete,
.socialMedia:not(:hover) > .delete {
  display: none;
}

.edit {
  display: inline-block;
  background-image: url("../Images/EditIcon.png");
  background-size: 100%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.delete {
  display: inline-block;
  background-image: url("../Images/X.png");
  background-size: 100%;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.socialMedia + .add {
  margin-top: 0.5rem;
}

.socialMediaIcon {
  width: 20px;
  height: 20px;
  background-size: 100%;
}

.content form {
  width: 100%;
  display: flex;
  gap: 0;
  align-items: center;
}

.content form input,
.content form textarea,
.content form select {
  flex: 1;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid rgb(221, 221, 221);
  padding: 0.25rem 0.375rem;
}

.location > select {
  cursor: pointer;
}

.location > select:focus,
.location input:focus {
  outline: none;
}

.content form button {
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  background-color: rgb(50, 100, 150);
  border-radius: 0.25rem;
  color: white;
  border: none;
  cursor: pointer;
}
.content form button:hover {
  background-color: rgb(40, 80, 120);
}

.socialMedia input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  transform: translateX(-1px);
}
.socialMedia select {
  flex: 0.5 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.tagOption:hover {
  filter: brightness(0.93) saturate(1.4);
}

.tagOption:not(.addTag) {
  color: var(--text);
  font-weight: 600;
  padding: 0.2em 0.5em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.tagOption > span {
  flex: 1;
}

.tagOption:last-child {
  margin-bottom: 0em;
}

.tagSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.tagSection > div {
  width: 12rem;
  padding: 0 0.1rem;
}

.grayLineSeparator {
  height: 1px;
  background-color: rgb(130, 130, 130);
  margin-bottom: 0.5em;
}

.addTagTitle {
  font-size: 1.2rem;
}

.addTag {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5em;
}

.addTag * {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.addTag input,
.addTag select {
  border: 1px solid rgb(130, 130, 130);
}
.addTag input:focus,
.addTag select:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}

.addTag .colorPickerWrapper {
  border-radius: 0.5rem;
  border: 1px solid rgb(130, 130, 130);
}

.addTag .colorPickerWrapper:focus-within {
  border-color: rgb(80, 80, 80);
}

.addTag input,
.addTag button,
.addTag select {
  padding: 0.2em 0.5em;
  font-family: inherit;
  border-radius: 0.5em;
}

.addTag button,
.addTag select {
  cursor: pointer;
}

.addTag > .category > span {
  padding-block: 0.2rem;
}

.addTag input[type="color"] {
  opacity: 0;
  cursor: pointer;
}

.addTag button {
  border: 1px solid rgb(130, 130, 130);
  background-color: white;
}

.addTag button:hover {
  border-color: rgb(80, 80, 80);
}

.addTag > .category {
  display: flex;
  gap: 0.5em;
}

.addTag > *:not(.spanHalf) {
  grid-column: span 2;
  max-width: 12rem;
}

.category {
  display: flex;
  align-items: center;
}

.cancelNewCategory {
  background-image: url("../Images/X.png");
  filter: contrast(0.7);
  background-size: contain;
  width: 15px;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px;
  cursor: pointer;
}

.cancelNewCategory:hover {
  filter: contrast(0.9);
}

.tagSectionHeader > span {
  overflow: hidden;
}

@media (hover: hover) {
  .tagOption:not(:hover) .editTag,
  .tagSectionHeader:not(:hover) .editTag {
    opacity: 0;
  }
}

.editTag:hover {
  filter: contrast(0.9);
}

.editTag {
  filter: contrast(0.7);
  background-size: contain;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  cursor: pointer;
}

.editTag {
  background-image: url("../Images/EditIcon.png");
}

.tagSectionTags {
  max-height: 15em;
  overflow-y: auto;
}

.tagSectionHeader {
  font-weight: 700;
  color: var(--text);
  margin-bottom: 0.75em;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tagSectionHeader > input {
  border: 1px solid rgb(130, 130, 130);
  padding: 0.2em 0.5em;
  font-size: 1rem;
  font-family: inherit;
  border-radius: 0.5em;
  width: 100%;
}

.tagSectionHeader > input:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}

.editInputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.editInputGroup input {
  border: 1px solid rgb(130, 130, 130);
  padding: 0.2em 0.5em;
  font-family: inherit;
  border-radius: 0.5em;
  width: 100%;
}

.editInputGroup input:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}

.editInputGroup .colorPickerWrapper {
  border-radius: 0.5rem;
  border: 1px solid rgb(130, 130, 130);
  width: 5rem;
}

.editInputGroup .colorPickerWrapper:focus-within {
  border-color: rgb(80, 80, 80);
}

.editInputGroup input[type="color"] {
  opacity: 0;
  cursor: pointer;
}

.deleteTag {
  display: grid;
  place-items: center;
}

.deleteTag > span {
  text-align: center;
  display: block;
  font-weight: 600;

  background-image: linear-gradient(#000000, #000000);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 150ms ease-in-out;
  cursor: pointer;
}

.deleteTag > span:hover {
  background-size: 100% 0.1em;
}

.spinner {
  background-image: url('../Images/spinner.gif');
  background-size: 180%;
  background-position: center;
  width: 4em;
  height: 4em;
  margin: 0;
  position: relative;
  filter: saturate(1.1) brightness(0.9);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.fullscreen::before {
  content: "";
  display: block;
  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}

.fullscreen::after {
  content: "";
  display: block;
  position: fixed;
  left: 0px;
  top: 0;
  width: 50px;
  height: 50px;
  background-image: url('../Images/spinner.gif');
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: 0;
  transform: translateY(-50%) translateX(-50%);
  width: 6em;
  height: 6em;
  margin: 0;
}
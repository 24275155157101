@media only screen and (max-width: 600px) {
  .container {
    width: 92vw;
  }
}

.topBar {
  display: flex;
  width: 100%;
  border-radius: 1.5em;
  box-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.1);
}

.addSensorBtn {
  color: white;
  font-family: var(--headingFont);
  background-color: var(--button);
  border: 2px solid var(--buttonHover);
  border-radius: 0em 1em 1em 0em;
  height: 100%;
  flex-basis: 145px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0em;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}

.addSensorBtn:hover {
  background-color: var(--buttonHover);
}

.searchInput {
  flex-grow: 1;
  box-sizing: border-box;
  background-color: white !important;
  border: var(--border);
  border-right: none;
  border-radius: 1em 0em 0em 1em;
  display: block;
  font-size: 1.2em;
  padding: 0.75em 2.5em;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.filebrowser {
  box-sizing: border-box;
  margin-bottom: 1em !important;
  padding: 0 !important;
  flex-grow: 1;
  padding-top: 1em;
  max-width: 850px;
  display: flex;
  max-height: calc(100vh - 115px);
  flex-direction: column;
}

.filebrowser > div {
  font-family: var(--mainFont);
}

.container > div {
  padding: 0;
  padding-top: 0.5em;
  width: 600px;
}

.warnings {
  display: flex;
  flex-direction: column;
}

.dangerBox {
  box-sizing: border-box;
  width: 100%;
  min-height: 40px;
  padding: 0.3em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}

.dangerBox.danger {
  background-color: rgb(255, 240, 240);
  border: 2px solid rgb(255, 129, 129);
}
.dangerBox.warning {
  background-color: rgb(255, 240, 230);
  border: 2px solid rgb(255, 130, 38);
}

.dangerBox.danger.isNew {
  background-color: rgb(255, 193, 193);
}
.dangerBox.warning.isNew {
  background-color: rgb(255, 208, 172);
}

.dangerBox > .dangerDescription {
  display: flex;
  align-items: center;
}

.dangerBox > .dangerDescription > div {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  height: 50%;
  width: 100px;
}

.dangerBox > .recommendations > ul {
  margin: 0;
}

.addRecommendation {
  width: 50%;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}

.addRecommendation > input {
  font-size: inherit;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0.1rem 0.5rem;
  flex: 1;
}
.addRecommendation > input:focus {
  outline: none;
}
.addRecommendation > button {
  font-size: 1.5rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 0.5rem;
  transform: translateX(-2px);
  cursor: pointer;
}

.dangerBox.danger .addRecommendation > input {
  border: 2px solid rgb(255, 129, 129);
}
.dangerBox.danger .addRecommendation > button {
  background-color: rgb(255, 225, 225);
  border: 2px solid rgb(255, 129, 129);
  color: rgb(255, 129, 129);
}
.dangerBox.danger .addRecommendation > button:hover {
  background-color: rgb(255, 193, 193);
}
.dangerBox.warning .addRecommendation > input {
  border: 2px solid rgb(255, 130, 38);
}
.dangerBox.warning .addRecommendation > button {
  background-color: rgb(255, 230, 210);
  border: 2px solid rgb(255, 130, 38);
  color: rgb(255, 130, 38);
}
.dangerBox.warning .addRecommendation > button:hover {
  background-color: rgb(255, 210, 174);
}

.recommendations > ul > div {
  display: flex;
  align-items: baseline;
}

@media (hover: hover) {
  .dangerBox:not(:hover) .closeWarning,
  .dangerBox:not(:hover) .openWarning,
  .dangerBox:not(:hover) .unreadEnvelop,
  .dangerBox:not(:hover) .readEnvelop {
    display: none;
  }
}

.closeWarning:hover,
.openWarning:hover,
.unreadEnvelop:hover,
.readEnvelop:hover {
  filter: contrast(0.9);
}

.closeWarning,
.openWarning,
.unreadEnvelop,
.readEnvelop {
  filter: contrast(0.7);
  background-size: contain;
  width: 40px;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  cursor: pointer;
}

.closeWarning {
  background-image: url("../Images/X.png");
}
.openWarning {
  background-image: url("../Images/ArrowRight.png");
}
.unreadEnvelop {
  background-image: url("../Images/UnreadEnvelop.png");
}
.readEnvelop {
  background-image: url("../Images/ReadEnvelop.png");
}

.recommendations > ul > div > .closeWarning {
  display: none;
  height: 13px;
}

.recommendations > ul > div:hover > .closeWarning {
  display: inline;
}

.notificationDropdown {
  border-bottom: 1px solid rgb(193, 193, 193);
  padding: 0.5em 1em;
}

.notifications {
  max-height: 300px;
  overflow-y: auto;
  display: grid;
  gap: 0.5em;
}

/* Firefox specific style */
@supports (-moz-appearance: none) {
  .tableCell.hasFirstChild {
    margin-top: 0.6em;
    margin-bottom: 0.35em;
  }
}
.notifications:has(:first-child) {
  margin-top: 0.6em;
  margin-bottom: 0.35em;
}

.dropdownButton {
  display: flex;
  align-items: center;
  user-select: none;
}

.newNotifCount {
  font-size: 0.8em;
  font-weight: 600;
  background-color: rgb(255, 193, 193);
  border: 2px solid rgb(255, 129, 129);
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  display: grid;
  place-items: center;
  margin-left: 0.65em;
}

.dropdownButton > .dropdownArrow:hover {
  filter: contrast(0.9);
}

.dropdownButton > .dropdownArrow {
  filter: contrast(0.7);
  background-image: url("../Images/DropdownArrow.png");
  background-size: contain;
  width: 40px;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.settingsBtn {
  background-color: rgb(221, 221, 221);
  height: 2.25rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 0.75rem;
  width: 100%;
  border: none;
}
.settingsBtn > span {
  margin-left: 0.25rem;
}
.settingsBtn > img {
  width: 1.35rem;
  height: 1.35rem;
}

.settingsBtn:hover {
  filter: contrast(0.75);
}

.uploadedFiles {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-height: 15rem;
  overflow-y: auto;
  margin-bottom: 0.25rem;
}

.uploadedFiles > * {
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  background-color: rgb(221, 221, 221);
  border-radius: 0.5rem;
  border: none;
}

.uploadBtn {
  cursor: pointer;
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  width: 100%;
  background-color: rgb(221, 221, 221);
  border-radius: 0.5rem;
  border: none;
}

.uploadBtn:hover {
  filter: contrast(0.75);
}

.deleteIcon {
  height: 1em !important;
  width: 1em !important;
  cursor: pointer;
}

.deleteIcon:hover {
  transform: scale(1.05);
}

.deviceManagerLoader {
  margin-top: 4em;
  display: flex;
  align-items: center;
}

.deviceManagerLoader > div {
  width: 4em;
}

.editReadme,
.viewReadme {
  position: relative;
}

.editReadme > button,
.viewReadme > button {
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: rgb(50, 100, 150);
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  font: inherit;
  position: sticky;
  bottom: 0;
  right: 0;
}
.editReadme > button:hover,
.viewReadme > button:hover {
  background-color: rgb(40, 80, 120);
}

.editReadme > button {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-1rem, -1rem);
}

.viewReadme {
  display: flex;
  flex-direction: column;
  max-height: 45rem;
  max-width: 45rem;
}

.viewReadme > button {
  align-self: flex-end;
}

.editReadme > textarea {
  flex: 1;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  border: 1px solid rgb(221, 221, 221);
  padding: 0.25rem 0.5rem;
}

.editReadme > textarea:focus {
  outline: none;
  border-color: rgb(100, 100, 100);
}

.machineSettings {
  display: grid;
  gap: 0.25rem;
  min-width: 15rem;
}

.machineSettings h2 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.machineSettingsElement {
  display: flex;
  align-items: center;
}

.machineSettingsElement > span {
  margin-right: 0.5rem;
}

.machineSettingsElement input {
  border: 1px solid rgb(130, 130, 130);
  padding: 0.2em 0.5em;
  font: inherit;
  border-radius: 0.5em;
  box-sizing: border-box;
  margin-left: auto;
  border-radius: 0.25rem;
}

.machineSettingsElement input:focus {
  outline: none;
  border-color: rgb(80, 80, 80);
}
